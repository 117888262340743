
.signup-drop-show{
    background-color: #212121;
    /* min-width: 120px !important; */
    /* max-width: 125px !important; */
    box-shadow: 0 4px 6px 1px #3e3b3b47;
    border-radius: 5px !important;
    opacity: 1px;
    pointer-events: all;
    position: absolute;
    /* top: 10px; */
    left: 130px;
    width: 120px;
    transform: translateY(0);
    transition: transform .5s ease-out;

}
.notshow{
    opacity: 0;
    /* height: 0px; */
    transform: translateY(20px);
    pointer-events: none;

}

.dropdown-item-login{
    display: block;
    width: 100%;
    padding: 0.4rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: white;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}