.m-20{
    margin: 20px;
}
.p-10{
    padding: 10px;
}
.mg-5{
    margin: 5px;
}
.excel-icon{
    font-size: 20px;
}