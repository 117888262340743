
.react-tel-input .form-control {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	font-size: 13px !important;
	padding-right: 0px !important;
	padding-left: 63px !important;
	border-radius: 0 !important;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	width: 100% !important;
  background: #fff !important;
  border: 1px solid #cacaca !important;
	font-size: 0.9rem !important;
    height: 38px !important;
    outline: none;
    border-radius: 0.25rem 0px 0px 0.25rem !important;
    &:focus {
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
        &.invalid-number {
          box-shadow: 0 0 0 0.2rem rgba(222,0,0,.25);
        }
      }
}
.react-tel-input .form-control+div:before{
  display: none;
}

.react-tel-input .country-list .country {
  padding: 5px 35px;
}
// .react-tel-input{
//   padding-left: 62px !important;

// }

// .react-tel-input .form-control{
//     width: 186px !important;
//     font-size: 13px !important;
//     padding-left: 42px !important;
//     padding-right: 0px !important;
//     height: 38px !important;
//     border-radius: 0.25rem 0px 0px 0.25rem !important;
// }
// .jms-h-100{
//     height: auto !important;
// }



