.ctnr {
    position: relative;
    
}
/* .olay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
  } */



  /* .referal {
    position: absolute;
    z-index: 1;
    transition: top .5s ease;
  } */

 

  .but {
    /* position: absolute;
    text-align: center; */
    background-color: cornflowerblue;
    opacity: 0;
    transition: opacity .35s ease;
    
  }

  .ctnr:hover .but {
    opacity: 1;
  }
  .ctnr:hover .rfc {
    opacity:0;
  }
  