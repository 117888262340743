.vendornew_wrapper{
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 50px 25px 0px 25px;
    flex-wrap: wrap;
    background-color: white;
    box-sizing: border-box;
}
.vendornew_header{
    display: flex;
    justify-content: space-between;
    border-bottom: 3px solid #3A436F;
    flex-wrap: wrap;
}
.vendor_logo{
    width: 233px;
    height: 181px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    /* background-color: red; */
}
.vendor_headname_warper{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: flex-end;
    width: 100%;
    border-bottom: 3px solid #3A436F;
    height: 181px;

}

.newvendor_name{
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-bottom: 20px;
}
.newvendor_social{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 40px;

}
.socila_links{
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #3A436F;
}


.vendornew_details_wrap{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.vendornew_details_sectionone{
    width: 234px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 25px 0px;
    border-radius: 11px;
}
.sectionone_one{
    width: 50%;
    height: 100%;
    background-color: #3A436F;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 11px;
}
.sectionone_one_2{
    width: 50%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3A436F;
    border-radius: 11px;
    cursor: pointer;
}
.vendornew_details_sectiontwo{
    margin-top: 50px;
    width: 234px;
    height: 157px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
}

.shareicon{
    height: 181px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.vendornew_sectiontwo{
    display: flex;
    flex-direction: column;
    width: calc(100vw - 24vw);
    /* background-color: violet; */
    align-items: flex-end;
}

.sectiontwo_one{
    margin: 25px 0px;
    border-radius: 10px;
    height: 33px;
    display: flex;
    background-color: #C4C4C4;
    flex-wrap: wrap;
    width: 100%;
}
.sectiontwo_one_tabs_active{
    width: 33.3%;
    border-radius: 10px;
    background-color: #3A436F;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.sectiontwo_one_tabs{
    width: 33.3%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.sectiontwo_two{
    width: 80%;
    word-wrap: break-word;
    margin-top: 50px;
}
.sectiontwo_two_p{
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}

.slider_newvendor{
    /* background: #dee2e6; */
    /* color: #6c757d; */
    height: 200px;
    margin: 0 1.5rem;
    transition: all .2s;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}
.slider_div{
    width: 80%;
    height: 185px;
    background: #FFFFFF;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.achievements{
    min-height: 185px;
    background: #FFFFFF;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    margin-top: 15px;
    
}
.achievements_curosel{
    width: 100%;
    height: 185px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.achievements_details{
    min-height: 185px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.achievements_head{
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    text-transform: capitalize;
    color: #3A436F;
}

.achievements_galll{
    font-family: 'Lato', sans-serif;
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    margin-right: 15px;
    color: #3A436F;
    cursor: pointer;
}

.achievements_p{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    margin-top: 18px;
}

.faculty_name{
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
}

.section_vendor_wrap{
    padding:80px 5px 20px 5px
}
@media screen and (max-width: 520px) {
    .section_vendor_wrap{
        padding:80px 10px 20px 10px
    }
  }

  @media screen and (max-width: 940px) {
    .vendor_logo {
        margin: auto;
    }
    .vendornew_details_sectionone{
        margin: auto;
        margin-top: 15px;
        margin-bottom: 55px;
    }
    .cusrosel_respons{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
    .newvendor_social{
        flex-direction: column;
    }

  }
  