.button_wrapper{
    width: 100%;
    background-color: #f9f9f9;
    padding: 5px;
    border: solid 1px #eee;
    outline: none;
    display: flex;
}
.cameratag_upload_button{
    border: 1px solid #0000007a;
    border-radius: 2px;
    padding: 2px 9px;
    min-width: 94px;
}
.camera_tag{
    font-family: inherit !important;
}
.camera_tag_span{
    color: #000000d9;
    margin-left: 5px;
    /* margin-top: 3px; */
    font-size: 13px;
}
.camera_tag .cameratag_screen {
    background: none !important;
    /* border-radius: 50% !important; */
    text-align: start;
    top: -15px !important;
}
.radial-progress{
    display: none;
}
.radial-progress .inset{
    display: none;
}

.camera_tag .cameratag_screen.cameratag_completed .cameratag_checkmark{
    display: none;
}
.camera_tag .cameratag_screen.cameratag_completed .cameratag_thumb_bg{
    display: none;
}
.camera_tag .cameratag_screen .cameratag_prompt{
    display: none;
}
.cameratag_spinner img{
    display: none;
}
@media screen and (max-width: 575px) {
    .logo_in_mobile {
      margin: 10px 0px;
    }
  }