.jms-subject-row-pad{
    padding: 20px;
}
.jms-create-video-session{
    text-transform: uppercase;
    padding: 18px 0px;
    font-weight: 700;
    margin-bottom: 5px;
    -webkit-box-shadow: 0 4px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    /* box-shadow: 0 8px 6px -6px black; */
    z-index: 9999;
}



