.courseview{
    cursor: pointer;
}

.courseview:hover{
    color: blue;
}

@media screen and (max-width: 991px){
.float-right {
float: right !important;
}
}