iframe{
    width: 100%;
    height: 342px;
}
.jms-span-video-details{
    position: absolute;
    float: right;
    padding: 7px;
    bottom: 19px;
    right: 4px;
    color: gray;
}
 /* .player-wrapper {
    border-bottom: 2px solid #62e5c8;
  } */
  .player-wrapper div  {
    /* border-bottom: 2px solid #62e5c8; */
    width: auto !important;
    position: relative;
  }
   
.confirm-seek{
  width: 70%;
  height: 200px;
  background-color:#171010ba;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%,-50%);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  opacity: 0;
  pointer-events: none;
  transition: all .5s ease-in;
}

.confirm-seek.show{
  opacity: 1;
  pointer-events: all;
}
  