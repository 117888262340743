
 .player-wrapper11 {
    border-bottom: 2px ;
  }
  .player-wrapper11 div  {
    width: auto !important;
  }
.videocardwrapp{
  height:60vh;
  /* background-color:red; */
  overflow-y: auto;
  scrollbar-width: 0;
} 
.videocardwrapp::-webkit-scrollbar {
  display: none;
}
.videocardwrapp {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
/* .videocardwrapp::-webkit-scrollbar-thumb {
  background: #FF0000;
} */

.videolist_div::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.videolist_div::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.videolist_div::-webkit-scrollbar-thumb {
  background: #c1c1c1; 
  border-radius: 5px;
}

/* Handle on hover */
.videolist_div::-webkit-scrollbar-thumb:hover {
  background:#888 
}
