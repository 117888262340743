.jms-thumb-details{
    padding: 10px 30px 10px 10px;
    border-left: 2px solid #dedede;
    line-height: 8px;
}
.jms-thumb-icon{
    text-align: center;
    padding: 10px 10px 10px 25px;
    margin: auto;
    color: #3be2c1;
}
.jms-thumb-card{
    padding: 10px 0px 10px;
    background-color: whitesmoke;
}
.jms-thumb-card:hover{
    background-color: #eaeaea;
    // .jms-thumb-action{
    //     background-color: #d3d0d0;
    // }
    
}
.jms-thumb-icon:hover{
   
       
        color: #e29f3b;
    .jms-video-count{
        color: #e29f3b;

    }
   
    

}
.jms-video-count{
    text-align: center;
    font-size: 10px;
    color: gray;
    margin-bottom: 0px;
}
.jms-thumb-action{
    display: flow-root;
    background-color: #e4e1e1;
    border-radius: 5px;
    padding: 6px 10px 6px 10px;
    font-size: 12px;
}
.jms-thumb-action-edit{
    color: #199ce8 !important;
     float: left !important;
}
.jms-thumb-action-edit:hover{
   text-decoration: underline;
   cursor:pointer ;
}
.jms-thumb-action-delete{
    color: #ff3b3b !important;
     float: right !important;
}
.jms-thumb-action-delete:hover{
    text-decoration: underline;
    cursor:pointer ;
 }
.jms-thumb-title{
    color: #329481;
    padding: 5px 5px 13px 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    display: inline-block;
    white-space: nowrap;
    width: 150px;
    height: 11px;
    font-weight: 600;
}
.jms-thumb-link:hover{
    .jms-thumb-title{
        text-decoration: underline;
    }
}
.jms-thumb-sub-details{
    color: gray;
     font-weight: 200;
     font-size: 12px;
     text-decoration: none !important;

     overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    display: inline-block;
    white-space: nowrap;
    width: 150px;
    height: 11px;
}

.jms-thumb-details a:hover{
    
     text-decoration: none !important;
}
.jms-video-thumb-col{
    padding: 10px ;
}
.jms-video-thumb-row{
    padding-left: 10px;
    padding-right: 10px;
}
.jms-video-thumb-row .center{
    text-align: center;
}
.jms-video-thumb-card{
    color: white;
   
    margin-bottom: 24px;
    background-image: linear-gradient(135deg,#010310,#0da8cc)!important;
}

.jms-playlist-save{
    width: -webkit-fill-available;
    height: 22px;
    font-size: 11px;
    padding: 0px;
    margin-bottom: 11px;
}