.jms-palylist-details-cards{
    margin-top:20px ;
}
.jms-playlist-details-inside-card{
    padding: 15px 15px 15px 30px;
}
.jms-choose-from-col-title{
    border-right:1px solid #dedada;
    overflow:scroll;
    overflow-x: hidden;
    max-height: 1500px;
    min-height:280px ;
 
}
.jms-choose-from-col-videos{
    overflow:scroll;
    overflow-x: hidden;
    max-height: 1500px;
    min-height:280px ;
    
}
.jms-choose-from-title{
    padding: 16px;
    background: #e8e8e8;
    font-weight: 600;
    border-radius: 3px;
    margin-top: 5px;
    color: rgb(184, 179, 179);
    text-transform: capitalize;
       
}
.jms-choose-from-title:hover{
    text-transform: capitalize;
     background: rgb(222, 222, 223);
    color: rgb(170, 165, 165);
}
.jms-active-playlist{
    background: rgb(41, 41, 97)!important;
    color:#f0cb29 !important;
}
.jms-position{
    position: absolute;
    left: 45%;
    text-align: center;
    top: 20%;

}

.video-js .vjs-big-play-button {
    top: 50%;
    left: 50%;
}
.vjs-playlist-title-container{
    margin-left: 10px !important;
}
.vjs-by{
    color: #007bff;
}
.vjs-playlist-now-playing-text {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    font-size: 13px;
    font-weight: bold;
    top: 1em;
    left: 1.5em;
    border-radius: 0.5em;
    margin: 0px;
    padding: 0.2em 0.8em;
    position: absolute;
}
.vjs-playlist-name {
    font-size: 13px;
    font-weight: bold;
    padding: 0px;
}
.btn-bs-file{
    position:relative;
}
.btn-bs-file input[type="file"]{
    position: absolute;
    top: -9999999;
    filter: alpha(opacity=0);
    opacity: 0;
    width:0;
    height:0;
    outline: none;
    cursor: inherit;
}