.jms-permision-card{
    background: #eff1f5;
    color: dimgrey;
}
.jms-enterprise-user-reg-col{
    height: 711px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.jms-enterprise-user-reg-col2{
    height: 750px;
    
}
