.assign-form-grid{
    padding-bottom: 10px;

}

.assign-button-grid{
    padding-top: 29px;
    padding-bottom: 10px;
}
.assign-form-button{
float: right;
}
.flex-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .assign-details-grid{
      padding-bottom: 20px;
  }
  .assign-details-card{
    padding: 20px;
}
.assign-details-card-div-head{
    line-height: 32px;
    font-weight: 600;
    color: gray !important;
}
.assign-details-card-div{
    line-height: 32px;
    
}
.assign-details-card-descrip {
    height: 330px;
    overflow-x: auto;
}
.assign-details-card-descrip-div{
    overflow-x: auto;
}
.assign-details-my-submission-mark{
    padding:10px;
    text-align: center;

}
.size-l strong{
    font-size: 50px;
}
.jms-opacity-overwrite .widget-subheading{
opacity: 1 !important;
}
.jms-opacity-overwrite .page-title-subheading{
    opacity: 1 !important; 
}
.jms-assign-specific-col{
    padding: 15px;
}
.jms-assign-specific-pad{
    padding-top: 15px;
}
// .jms .dropdown-menu-header{
//     color:'black'
// }
.jms-bg-overrwrite{
    background-color:#99ddff !important;
}
.jms-bg-over2{
    background-color: #99ddff !important;
    min-height: 347px !important;
}