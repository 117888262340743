
.input-group .MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: none;
  border-bottom: 1px solid #dfe4ea;
  pointer-events: none;
}

.input-group .MuiInput-underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: none;
  border-bottom: 2px solid #dfe4ea;
  pointer-events: none;
}
.input-group-sm > .form-control:not(textarea), .input-group-sm > .custom-select {
  /* height: calc(1.5em + 0.5rem + 2px); */
  height: 100%;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #144990;
  text-align: center;
  white-space: nowrap;
  background-color:white;
  border: none;
  border-radius: 0.25rem;
}

.owl-theme .owl-dots .owl-dot.active span{
    /* background: #FD4720 !important; commented by dhanasekar */
    background: #0572aa!important;

  }

  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    /* background: #FD4720 !important;  commented by dhanasekar*/
    background: #0572aa!important;
  }


  .dropdown-menu::before {
    background: none;
  }

  .curoselstyle{
    width: 100%;
    height: 460px;
  }
  
  .curoselitem{
    width: auto;
    height: 450px;
  }
  
  .newcurclass{
    margin-top: 100px;
  }

  .list-tab{
    display: none;
  }

  .main-search-website{
    display: none;
  }

  .purchase_div_mobile{
    display: none;
  }
  @media only screen and (max-width: 973px){
    .main-search-website{
      display: inline;
    }
  }
  @media only screen and (max-width: 990px) {
    .signup-drop-menu-fix{
      left: 70% !important;
      top: 13% !important;
    }
  }

  @media only screen and (max-width: 768px) {
    
    .newcurclass{
      margin-top: 150px;
    }
    .contactus{
      margin-bottom: 10px;
    }
    .curoselstyle{
      width: 100%;
      height: 380px;
    }
    
    .curoselitem{
      width: auto;
      height: 350px;
    }
    .purchase_div{
      display: none;
    }
    .purchase_div_mobile{
      display: block;
    }
  }
  
 
  @media screen and (min-device-width: 480px) and (max-device-width: 650px){
    .curoselstyle{
      width: 100%;
      height: 345px;
    }
    
    .curoselitem{
      width: auto;
      height: 290px;
    }
  }
  @media only screen and (max-width: 480px) {
    #tabs-nav > li{
      /* width: 100%; */
      display: none;
    }
    /* .tabnavlist{
      display: none;
    } */
    .curoselstyle{
      width: 100%;
      height: 250px;
    }
    
    .curoselitem{
      width: 100%;
      height: 200px;
    }

    .item-thumbnail{
      display: block;
      width: 80%;
      margin-left: 11px;
  }
  .list-tab{
    display: block;
  }
  .spanres{
    cursor: pointer;
    color: #383838;
    font-size: 15px !important;
    font-weight: 500 !important;
    text-align: left;
  }
  }

  @media screen and (min-device-width: 991px) and (max-device-width: 1200px){
    /* .head-btn{
      color: #000 !important;
      background: none;
    } */
    
    header ul.link-padding-tb-25px .mega-menu-out {
      top: 143px;
    }
  }

  @media screen and (min-device-width: 1400px) and (max-device-width: 2500px){
    .curoselstyle{
      width: 100%;
      height: 70vh;
    }
    
    .curoselitem{
      width: 100%;
      height: 70vh;
    }
  }

  .player-wrapper-course div  {
    /* border-bottom: 2px solid #62e5c8; */
    width: auto !important;
    height: 215px !important;
  }

  
  @media only screen and (max-width: 990px) {
    .signup-margintop{
      margin:10px 0px 15px;
    }
  }