.jms-choose-from-playlist-thumb-card{
    border-radius: 5px;
    border-radius: 5px;
    background-size: contain;
    height: 125px;
    padding-top: 10px;
    margin-top:10px;
    margin-left: 7px
}

.jms-choose-from-playlist-thumb-title{
  
    color: white;
    padding: 1px 7px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 11px;
    background: #2f2d2d;
    position: absolute;
    background-repeat: no-repeat;
    top: 15px;
    left: 30px;
}
.jms-choose-from-playlist-thumb-size{
    color: white;
    padding: 1px 7px;
    border-radius: 4px;
    font-size: 11px;
    background: #2f2d2d;
    position: absolute;
    top: 15px;
    right: 30px;
}


.jms-choose-from-playlist-thumb-format{
    color: white;
    padding: 1px 7px;
    border-radius: 4px;
    font-size: 11px;
    background: #2f2d2d;
    position: absolute;
    bottom: 5px;
    right: 25px;
}
.jms-choose-from-playlist-thumb-format-choose{
    color: white;
    padding: 1px 7px;
    border-radius: 4px;
    font-size: 11px;
   
    position: absolute;
    top: 15px;
    right: 22px;
}
.jms-choose-from-playlist-thumb-video-col-3{
    // border-left: 1px solid gainsboro;
}
.jms-choose-from-playlist-thumb-checkbox{
position: absolute;
top:50%;
left:0px
}



// media query
@media screen and (max-width: 768px) {
    .jms-choose-from-playlist-thumb-card {
      height: 260px;
    }
  }

  @media only screen and (max-width: 500px) {
    .jms-choose-from-playlist-thumb-card {
        height: 200px;
      }
  }
.jms-number-height{
    width: 95px !important;
    height: 25px !important;
}
