/* //added by dhanasekar */
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap'); */
*
{
    margin: 0;
    padding: 0;
     
    font-family: 'Roboto';

    
}
/* //added by dhanasekar */
body
{
    overflow-x: hidden;
}
p
{
    font-family: 'Roboto', sans-serif !important;
}
.bg-blue
{
    background: #144990;
}
.vi-logo
{
    max-width: 94px;
    min-width: 94px;
}
.main-search
{
    background: #ffffff;
    padding: 35px 55px 15px 55px;
    position: absolute;
    left: auto;
    top: 55vh;
    z-index: 8;
    display: block;
    width: 80%;
    margin-left: 10%;
    margin-right: auto;
    right: auto;
    border-radius: 0 5px 5px 5px;
    box-shadow: 0 2px 15px 7px #0000001a;
}
.search-col-title-col
{
    position: absolute;
    top: -36px;
    left: 0;
    text-align: left !important;
}
.search-col-title
{
    font-weight: 400;
    font-size: 16px !important;
    text-transform: capitalize;
    color: #ffffff;
    text-align: left !important;
    border-bottom: 1px solid #e2e2e2;
    line-height: 30px;
    margin-bottom: 0;
    display: inline-block;
    /* background: #000000eb; commented by dhanasekar */
    background: #20a8d8;
    padding: 3px 30px;
    border-radius: 5px 5px 0 0;
}
.tp-fullwidth-forcer {
    height: calc(100vh - 130px) !important;
}
.search-form-main .form-control
{
    border-color: #92929236 !important;
    /* border-radius: 0 !important; //commented by dhanasekar */
    border-radius: 10px 0px 0px 10px!important;
    padding: 10px 15px !important;
    height: 45px !important;
    font-size: 14px !important;
}
.search-form-main .form-control:focus
{
    outline: none !important;
    box-shadow: none !important;
}
.search-form-main .search-btn
{
    height: 45px !important;
    /* background: #36BDAC; //commmented by dhanasekar */
    background: #20a8d8;
    /* border-radius: 0 4px 4px 0;//commmented by dhanasekar */ 
    border-radius: 0 10px 10px 0;
    color: #ffffff;
    font-weight: 500;
    position: inherit !important;
    left: auto !important;
}
.search-form-main .search-btn:hover
{
    /* background: #1b833b; //commmented by dhanasekar  */
    background: #0572aa;
    cursor: pointer;
    transition: all .3s;
}
.search-form-main label {
    font-size: 12px !important;
    margin-bottom: 2px !important;
    color: #21a349a6;
    font-weight: 500 !important;
}
.font-11
{
    font-size: 11px !important;
}
.font-12
{
    font-size: 12px !important;
}
.font-13
{
    font-size: 13px !important;
}
.font-14
{
    font-size: 14px !important;
}
.font-15
{
    font-size: 15px !important;
}
.font-16
{
    font-size: 16px !important;
}
.font-18
{
    font-size: 18px !important;
}
.fw-200
{
    font-weight: 200 !important;
}
.fw-400
{
    font-weight: 400 !important;
}
.fw-500
{
    font-weight: 500 !important;
}
.fw-600
{
    font-weight: 600 !important;
}
.fw-700
{
    font-weight: 700 !important;
}
.fw-800
{
    font-weight: 800 !important;
}
.text-para
{
    font-weight: 400 !important;
    font-size: 15px !important;
    letter-spacing: inherit !important;
    line-height: 24px !important;
}
.text-lead
{
    font-weight: 500 !important;
    font-size: 17px !important;
    letter-spacing: inherit !important;
    line-height: 26px !important;
    color: #21a349cc;
}
.bg-light
{
    background: #f3f3f3;
}
.featured-institute-main
{
    background: #ffffff;
    /* box-shadow: 0px 1px 15px 3px #0000000f;  commented by dhansekar */
}
.f-i-title
{
    font-size: 15px !important;
    font-weight: 500 !important;
    min-height: 52px;
    max-height: 53px;
    display: block;
    overflow: hidden;
}
.cat-div
{
    position: absolute;
    left: 15px;
    bottom: 15px;
    display: block;
    background: #000000c4;
    color: #fff;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 500 !important;
    padding: 0 6px !important;
    border-radius: 3px !important;
}
.text-link
{
    font-size: 14px;
    font-weight: 600;
    color: #144990;
    transition: all .3s;
}
.text-link:hover
{
    color: #2c7be6;
    transition: all .3s;
}
.btn-gn
{
    /* background: #36BDAC; commented by dhanasekar */
    background: #20a8d8;
    color: #fff !important;
    font-weight: 500 !important;
    padding: 8px 35px;
    font-size: 15px !important;
    text-transform: uppercase;
    line-height: 26px !important;
    border-radius: 30px !important;
    transition: all .3s;
    position: inherit !important;
    left: inherit !important;
}
.btn-gn:hover
{
    /* background: #040c18; commented by dhanasekar */
    background: #0572aa;
    transition: all .3s;
}
a:hover {
    color: #144990;
    text-decoration: none;
}
.head-btn
{
    padding: 4px 10px !important;
    border-radius: 5px !important;
    background: #21a349;
    opacity: 1 !important;
    transition: all .3s;
    color: #fff !important;
}
.head-btn:hover
{
    background: #222222;
    color: #fff !important;
    transition: all .3s;
}
header ul.nav-menu > li > a:hover {
    color: #144990;
}
header ul.dropdown-dark li > ul.sub-menu > li a:hover:before {
    color: #21a349 !important;
}
header ul.dropdown-dark .mega-menu-out .mega-menu-list li a:hover:before {
    color: #21a349 !important;
}
.fullwidthbanner-container {
    overflow: hidden;
    height: 70vh !important;
}
.category-col
{
    display: block;
    background: #fff;
    padding: 20px 15px;
    border-radius: 5px !important;
    border: 1px solid #eaeaea;
    height: 100%;
}
.category-col .img-col
{
    border-radius: 100px;
    padding: 14px;
    background: #ffffff;
    border: 1px solid #c3c3c3;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    min-width: 75px;
    max-width: 75px;
    transition: all .4s;
    box-shadow: 0px 2px 10px -2px rgba(73, 144, 64, 0.02);
}
.category-col:hover
{
    color: #fff;
    box-shadow: 0px 2px 10px -2px #14499040;
    transition: all .5s;
    cursor: pointer;
    background: #144990;
}
.category-col:hover.category-col .img-col
{
    transform: rotateY(180deg);
    transition: all .6s;
    background: #fff;
}
.category-col h5
{
    text-transform: capitalize;
}
footer .logo
{
    min-width: 175px;
    max-width: 175px;
}
.footer-menu-2 {
    font-size: 14px;
    text-transform: capitalize;
}
.f-s-link {
    border: 1px solid #b7b7b7;
    padding: 4px 10px !important;
    min-width: 35px;
    max-width: 35px !important;
    display: block;
    text-align: center;
    font-size: 15px;
    border-radius: 3px;
}
.facebook:hover
{
    color: #0d529a;
    transition: all .3s;
}
.youtube:hover
{
    color: #f70f0f;
    transition: all .3s;
}
.linkedin:hover
{
    color: #0080bd;
    transition: all .3s;
}
.twitter:hover
{
    color: #08d3ff;
    transition: all .3s;
}
.instagram:hover
{
    color: #f09433;
    transition: all .3s;
}
.bg-blue
{
    background-color: #144990;
}
.icon-round {
    width: 65px;
    height: 65px;
    border-radius: 50px;
    background-color: #21a349;
    color: #fff;
    text-align: center;
    padding-top: 17px;
    font-size: 32px !important;
    margin-bottom: 15px;
    border: 1px solid #21a349;
}
.institute-media
{
    height: 140px;
    width: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px 5px 0 0 !important;
    margin-bottom: 10px;
}
.swiper-pagination {
    bottom: -5px !important;
}
.courst-btn
{
    border: 1px solid #2E77BC;
    font-size: 13px !important;
    font-weight: 500;
    /* border-radius: 0 !important; */
    padding: 6px 15px !important;
    text-align: center;
    transition: all .3s;
    text-transform: uppercase;
    color: #144990;
    border-radius: 5px;
}
.courst-btn:hover
{
    color: #ffffff;
    /* border: 1px solid #144990;
    background: #144990; */
    border: 1px solid #0572aa;
    background: #0572aa;
    transition: all .3s;
}
.review-box {
    border: 1px solid #f1f1f1;
    padding: 10px 15px;
    border-radius: 5px !important;
    height: 100%;
    background: #f7f7f7fc;
}
.swiper-testimonial2 .review-box
{
    border: 1px solid #eaeaea;
    border-radius: 0 !important;
    padding: 0 !important;
    transition: all .3s;
    text-transform: capitalize;
    text-align: center;
    background: #fff;
}
.swiper-testimonial2 .review-box:hover
{
    box-shadow: 0 2px 10px -2px #14499040;
    transition: all .3s;
}
.swiper-testimonial .review-box:hover
{
    box-shadow: 0 2px 10px -2px #14499040;
    transition: all .3s;
}
.swiper-testimonial2 .course-media
{
    height: 160px;
    width: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0 !important;
    margin-bottom: 10px;
}
.course-slide-title
{
    font-size: 16px;
    font-weight: 500;
    min-height: 46px !important;
    max-height: 46px !important;
    text-transform: capitalize;
    padding: 1px 10px;
}
.course-time-col
{
    border-top: 1px solid #e4e4e4;
    padding: 2px 10px;
    border-bottom: 1px solid #e4e4e4;
    background: white;
}
.border-light-right
{
    border-right: 1px solid #e4e4e4;
}
.icon-img-col
{
    background: #21a349;
    width: 80px;
    height: 80px;
    border-radius: 80px;
    padding: 6px;
    margin-bottom: 15px;
}
header ul.nav-menu .mega-menu-out .mega-menu-list li a
{
    padding: 8px 0 4px 0;
}
header ul.nav-menu .mega-menu-out
{
    left: 12%;
    width: 76%
}
.white-link
{
    color: #ffffff !important;
}
.white-link:hover
{
    color: #3ee271 !important;
}
.bg-image
{
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.logo-box
{
    padding: 0 20px;
    border-radius: 5px !important;
    height: 100%;
    background: #ffffff;
}
.fa-phone {
    transform: rotate(90deg);
}
.course-badges
{
    font-size: 11px !important;
    font-weight: 500 !important;
    color: #ffffff !important;
    padding: 4px 7px 3px 7px !important;
    text-transform: uppercase !important;
}



/* Tabs */
.tabs {
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px 5px 5px 5px;
    transition: all .3s;
}
ul#tabs-nav {
    list-style: none;
    margin: 0;
    padding: 5px 0 0 0;
    overflow: auto;
    display: inline-block;
    transition: all .3s;
}
ul#tabs-nav li {
    float: left;
    font-weight: bold;
    margin-right: 2px;
    padding: 8px 20px;
    border-radius: 5px 5px 5px 5px;
    /*border: 1px solid #d5d5de;
    border-bottom: none;*/
    cursor: pointer;
    transition: all .3s;
}
ul#tabs-nav li:hover,
ul#tabs-nav li.active {
    background-color: #ffffff;
    transition: all .3s;
}
ul#tabs-nav li.active a
{
    /* color: #08E; commented by dhansekar */
    color: #20a8d8;
    transition: all .3s;
}
#tabs-nav li a:hover
{
    /* color: #08E; commented by dhansekar */
    color: #20a8d8;
    transition: all .3s;
}
#tabs-nav li a {
    text-decoration: none;
    color: #383838;
    font-size: 15px !important;
    font-weight: 500 !important;
    transition: all .3s;
}
.tab-content {
    background-color: #ffffff;
    padding: 20px 5px !important;
    transition: all .3s;
}
.active
{
    transition: all 2s;
}
.fullwidthabanner {
    height: 70vh !important;
}

@media screen and (min-width: 320px) and (max-width: 990px)
{
    header ul.nav-menu .mega-menu-out .mega-menu-list li a
    {
        padding: 8px 0 4px 0;
    }
    header ul.nav-menu .mega-menu-out
    {
        left: 0;
        width:100%
    }
    #logo img
    {
        width:60%
    }
    header ul.nav-menu {
        padding-bottom: 10px;
    }
    .search-col-title {
        font-weight: 400;
        font-size: 14px !important;
        text-transform: capitalize;
        color: #ffffff;
        text-align: left !important;
        border-bottom: 1px solid #e2e2e2;
        line-height: 30px;
        margin-bottom: 0;
        display: inline-block;
        /* background: #000000eb; commented by dhanasekar */
        background: #20a8d8;
        padding: 3px 20px;
        border-radius: 5px 5px 0 0;
    }
    .main-search {
        background: #ffffff;
        padding: 25px 35px 10px 35px;
        position: absolute;
        left: auto;
        top: 55vh;
        z-index: 9999;
        display: block;
        width: 80%;
        margin-left: 10%;
        margin-right: auto;
        right: auto;
        border-radius: 0 5px 5px 5px;
        box-shadow: 0 2px 15px 7px #0000000d;
    }
    .search-form-main .form-control {
        border-color: #92929236 !important;
        /* border-radius: 0 !important; //commented by dhanasekar */
        border-radius: 10px 0px 0px 10px!important;
        padding: 6px 15px !important;
        height: 35px !important;
        font-size: 13px !important;
    }
    .text-title-large {
        font-size: 32px;
        line-height: 40px !important;
    }
    .tp-fullwidth-forcer {
        height: calc(100vh + 50px) !important;
    }
    .vi-logo
    {
        min-width: 64px;
        max-width: 64px;
        position: absolute;
        top: 10px;
        right: 60px;
    }
    header a.mobile-toggle
    {
    margin: 6px 10px;
    }
    ul#tabs-nav li
    {
        padding: 4px 20px;
    }
}


@media screen and (min-width:320px) and (max-width: 480px)
{
    .vi-logo-col
    {
        min-width: 64px;
        max-width: 64px;
        position: absolute;
        top: 10px;
        right: 70px !important;
        display: block;
        height: 64px;
        min-height: 64px;
        max-height: 64px;
    }
    /* .vi-logo-col { */
        /*position: absolute;
        top: 15px !important;
        left: 260px;*/
    /* } */
    .vi-logo-col img
    {
        max-width: 64px !important;
        min-width: 64px !important;
    }
}


@media screen and (min-width: 481px) and (max-width: 1024px)
{
    ul#tabs-nav li {
        float: left;
        font-weight: bold;
        margin-right: 2px;
        padding: 8px 10px;
        border-radius: 5px 5px 5px 5px;
        cursor: pointer;
    }
    .main-search {
        background: #ffffff;
        padding: 35px 30px 15px 30px;
    }
    .fullwidthbanner-container .fullwidthabanner
    {
        height: 27vh !important;
    }
    .fullwidthbanner-container
    {
        height: 27vh !important;
    }
    .main-search
    {
        top: 24vh;
        padding: 25px 25px 10px 25px;
        width: 90%;
        margin-left: 5%;
        margin-right: auto;
    }
    #tabs-nav li a
    {
        font-size: 13px !important;
    }
    .vi-logo-col
    {
        min-width: 64px;
        max-width: 64px;
        position: absolute;
        top: 10px;
        right: 160px;
        display: block;
        height: 64px;
        min-height: 64px;
        max-height: 64px;
    }
    /* .vi-logo-col { */
        /*position: absolute;
        top: 15px !important;
        left: 260px;*/
    /* } */
    .vi-logo-col img
    {
        max-width: 77px !important;
        min-width: 77px !important;
    }
    .tp-fullwidth-forcer {
        height: calc(100vh - 64vh) !important;
    }
    .search-form-main .search-btn
    {
        height: 35px !important;
    }
}





.swiper-button-prev-test
{
    z-index: 999999999 !important;
    position: absolute;
    left: 5px !important;
    opacity: 1 !important;
    width: 40px !important;
    height: 40px !important;
    display: inline-block !important;
    top: 40% !important;
    background: #00000062;
    border-radius: 50px;
}
.swiper-button-next-test
{
    z-index: 999999999 !important;
    position: absolute;
    right: 5px !important;
    opacity: 1 !important;
    width: 40px !important;
    height: 40px !important;
    display: inline-block !important;
    top: 40% !important;
    background: #00000062;
    border-radius: 50px;
}
.swiper-button-prev-test:hover, .swiper-button-next-test:hover
{
    cursor: pointer;
}

















/* now move prev button more to the left */
.swiper-button-prev-test {
    /* but first hide on small viewports */
    display: none;

    left: 0;
    top: 0;

    margin-top: 0;

    /* subtract .swiper-wrapper bottom-margin to vertically center arrow */
    height: calc(100% - 3em);
}

@media (min-width: 37.5em) {
    .swiper-button-prev-test {
        /* show on larger viewports */
        display: block;
    }
}

.swiper-button-next-test {
    /* hide on small viewports */
    display: none;

    right: 0;
    top: 0;

    margin-top: 0;

    /* subtract .swiper-wrapper bottom-margin to vertically center arrow */
    height: calc(100% - 3em);
}

@media (min-width: 37.5em) {
    .swiper-button-next-test {
        /* show on larger viewports */
        display: block;
    }
}



/* 19 - 05 - 2021 */
.courst-btn
{
    position: inherit !important;
}
.bg-dark-blue
{
    background: #144990 !important;
}
.text-yellow
{
    color: #ffdd61 !important;
}
.top-banner
{
    padding: 100px 0 !important;
    background: gainsboro;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.top-banner .banner-title
{
    font-size: 32px !important;
    color: #fff;
    text-transform: uppercase;    /* added by dhanasekar */
}
.inner-search
{
    background: #ffffff;
    padding: 35px 55px 15px 55px;
    position: absolute;
    left: auto;
    bottom: -65px;
    display: block;
    width: 80%;
    margin-left: 10%;
    margin-right: auto;
    right: auto;
    border-radius: 0 5px 5px 5px;
    box-shadow: 0 2px 15px 7px #0000001a;
}
.pagination-list
{
    display: inline-block;
}
.pagination-list li
{
    display: inline-block;
    margin: 0 3px !important;
}
.pagination-list li a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
}
.pagination-list li a.active
{
    background: #144990 ;
    color: #ffffff;
    border: 1px solid #144990;
}

.pagination-list {
    display: inline-block;
    padding: 0 !important;
}

.pagination-list li a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    border: 1px solid #ddd;
    font-weight: 500 !important;
}
.pagination-list li a:hover:not(.active) {background-color: #144990;
    color: #fff;
    border: 1px solid #0d4084;}

.pagination-list li a:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.pagination-list li a:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.institute-col
{
    transition: all .3s;
}
.institute-col:hover
{
    box-shadow: 0 2px 10px -2px #14499040;
    transition: all .3s;
}
.btn-signup
{
    background: transparent !important;
    border: none !important;
    font-size: 13px;
    padding: 0 5px;
    position: inherit !important;
    left: initial !important;
}
.btn-signup:hover
{
    cursor: pointer;
    color: #3ee271;
}
.btn-signup:focus
{
    outline: none !important;
    box-shadow: none !important;
}
.signup-drop
{
    background-color: #212121;
    min-width: 120px !important;
    max-width: 125px !important;
    box-shadow: 0 4px 6px 1px #3e3b3b47;
    border-radius: 5px !important;
}
.signup-drop a
{
    font-size: 14px !important;
    color: #d2d2d2;
    transition: all .3s;
    padding: 4px 15px !important;
}
.signup-drop a:hover
{
    background: transparent;
    color: #ffffff;
    transition: all .3s;
}
.institute-media
{
    text-align: center;
    background: #fff !important;
}
.course-banner
{
    padding: 140px 0 !important;
    background: gainsboro;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.banner-institute-logo
{
    position: absolute;
    width: 200px;
    height: 200px;
    background: white;
    border: 6px solid white;
    padding: 15px 15px !important;
    bottom: -80px;
    box-shadow: 0 4px 17px 0 #00000026;
    border-radius: 3px !important;
}
.banner-institute-logo img
{
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: auto !important;
    margin-top: auto !important;
}
.btn-download {
    background: #21a349;
    color: #fff;
    padding: 9px 20px !important;
    font-weight: 500 !important;
    transition: all .3s;
    position: inherit !important;
    left: inherit !important;
}
.btn-download:hover
{
    background: #2a2b2a;
    transition: all .3s;
    color: #fff !important;
}
.text-title-medium
{
    font-size: 32px;
    line-height: 46px;
}
.light-bg-title
{
    background: whitesmoke;
    padding: 5px 12px !important;
}
.course-fee-col {
    background: #484848;
    border-radius: 7px !important;
    position: relative;
    margin-top: -120px !important;
    padding: 30px !important;
    box-shadow: 0 3px 12px -2px #0000004f;
    /* border: 1px solid #000853; */
}
.course-right-col
{
    background: whitesmoke;
    padding: 20px 15px !important;
    border: 1px solid #e8e7e7;
    border-radius: 4px !important;
    box-shadow: 0 3px 12px -2px #0000001a;
}
.course-learn-ul
{
    padding-left: 0;
}
.course-learn-ul li
{
    list-style: none !important;
    position: relative;
    padding-left: 27px;
    font-size: 15px !important;
    line-height: 22px !important;
    margin-bottom: 15px !important;
    font-weight: 500;
}
.course-learn-ul li:before
{
    list-style: none;
    position: absolute;
    content: url('../images/tick-icon.png');
    display: block;
    z-index: 22;
    left: 0;
    top: 0;
}
.form-group-prepend {
    border-left: 1px solid #dfe4ea;
    border-right: none !important;
    border-bottom: 1px solid #dfe4ea;
    border-top: 1px solid #dfe4ea;
    /* padding: 11px 14px !important;  commented by dhanasekar*/  
    padding: 8px 14px !important;  /* added by dhanasekar */
    border-radius: 4px 0 0 4px !important;
    background: #fff !important;
    color: #144990;
    /* border-color:#dfe4ea; */
}
.form-group-append {
    border-right: 1px solid #dfe4ea;
    border-left: none !important;
    border-bottom: 1px solid #dfe4ea;
    border-top: 1px solid #dfe4ea;
    /* padding: 11px 14px !important; */
    /* border-radius: 4px 0 0 4px !important; */
    background: #fff !important;
    color: #144990;
    /* border-color:#dfe4ea; */
}

.code-input{
    border: none !important;
    padding: 0px 0px 0px 10px !important;
    width: 110px !important
}
.code-input:focus
{
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
}
.gn-form-control {
    padding: 12px 15px 12px 10px !important;
    font-size: 14px !important;
    border-radius: 0 4px 4px 0 !important;
    border-left: none !important;
    border-right: 1px solid ;
    border-top: 1px solid ;
    border-bottom: 1px solid;
    border-color:#dfe4ea;
}
.gn-form-control:focus
{
    box-shadow: none !important;
    outline: none !important;
    border-color:#dfe4ea;
}
.form-input-btn{
    /* background: #144990; //commented by dhanasekar
    border: 1px solid #104184; */
    background: #20a8d8;
    border: 1px solid #20a8d8;
    padding: 11px 10px;
    color: #fff;
    font-weight: 500;
    border-radius: 4px !important;
    transition: all .3s;
}
.form-input-btn:hover
{
    /* background: #08336d; //commented by dhansekar
    border: 1px solid #0b356f; */
    background: #0572aa;
    border: 1px solid #0572aa;
    transition: all .3s;
    cursor: pointer;
}
.gn-form-control:focus .input-group
{
    box-shadow: 0 2px 7px -1px rgba(33, 163, 73, 0.4);
}
.input-group
{
    box-shadow: 0 2px 7px -1px rgba(0, 0, 0, 0.09);
}
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button { -webkit-appearance: none; -moz-appearance: none; appearance: none; margin: 0; }
.ftr-column1
{
    padding: 40px 25px;
    border: 1px solid #eaeaea;
    border-bottom: 4px solid #21a349;
    box-shadow: 0 6px 10px -1px #00000026;
    transition: all .3s;
    background: #fff;
}
.ftr-column1:hover
{
    padding: 40px 25px;
    border: 1px solid #eaeaea;
    border-bottom: 4px solid #144990;
    box-shadow: 0 6px 10px -1px #14499042;
    cursor: pointer;
    transition: all .3s;
    background: whitesmoke;
}
.ftr-column1 img
{
    min-height: 50px;
    max-height: 50px;
    margin-bottom: 5px;
}
.ftr-column1 h2 {
    font-size: 17px !important;
    text-transform: uppercase !important;
    margin-bottom: 5px !important;
    font-weight: 600 !important;
}
.lh-22
{
    line-height: 22px !important;
}
.lh-24
{
    line-height: 24px !important;
}
.why-tab-nav
{
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    padding: 10px 25px !important;
}
.why-tab-nav.active {
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    padding: 10px 25px !important;
    color: #ffffff !important;
    border-color: #144990 #144990 #144990 !important;
    background: #144990 !important;
}
.why-tab-content {
    background-color: #f3f3f3;
    padding: 20px 10px !important;
    transition: all .3s;
    border-radius: 0 0 6px 6px !important;
    border: 1px solid #dddddd;
}
.category-col-inner {
    display: block;
    background: #fff;
    padding: 24px 15px 20px 15px;
    border-radius: 0px !important;
    border: 1px solid #eaeaea;
    height: 100%;
    border-bottom: 4px solid #20a8d8;
    transition: all .3s !important;
}
.category-col-inner:hover
{
    border: 1px solid #144990;
    border-bottom: 4px solid #144990 !important;
    transition: all .3s !important;
    background: #1449900a;
}
.form-main-col
{
    border: 1px solid #f5f5f5;
    border-radius: 5px !important;
    padding: 0;
}
.login-col
{
    padding: 30px 60px;
    background: #f5f5f5;
}
.large-modal
{
    min-width: 84%;
    max-width: 84% !important;
}






#main {
    margin: 30px 0;
}

#main #faq .card {
    margin-bottom: 10px;
    border: 0;
}

#main #faq .card .card-header {
    border: 0;
    -webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
    box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
    border-radius: 2px;
    padding: 0;
}

#main #faq .card .card-header .btn-header-link {
    color: #fff;
    display: block;
    text-align: left;
    background: #e8e8e8;
    color: #222;
    padding: 15px 20px;
}

#main #faq .card .card-header .btn-header-link:after {
    content: "\f107";
    font-family: 'FontAwesome';
    font-weight: 900;
    float: right;
    position: absolute;
    right: 15px !important;
    top: 15px;
}

#main #faq .card .card-header .btn-header-link.collapsed {
    background: #fdfdfd;
    color: #1d1f21;
    border: 1px solid #e6e6e6;
}

#main #faq .card .card-header .btn-header-link.collapsed:after {
    content: "\f106";
}

#main #faq .card .collapsing {
    background: #e8e8e8;
    line-height: 30px;
}

#main #faq .card .collapse {
    border: 0;
}

#main #faq .card .collapse.show {
    background: #f5f5f5;
    line-height: 30px;
    color: #222;
}
.coupon-input
{
    border-radius: 4px 0 0 4px !important;
    padding: 10px 15px !important;
    border-color: #e8e8e8 !important;
    border-right: none !important;
}
.coupon-input:focus
{
    outline: none !important;
    box-shadow: none !important;
}
.aply-cpn-btn
{
    border-radius: 0 4px 4px 0 !important;
    background: #144990 !important;
    color: #fff !important;
    padding: 12px 10px;
    border: 1px solid #265384;
    font-size: 14px !important;
}
.border-link
{
    color: #ffffff !important;
    border: 2px solid #6a9bdc;
    padding: 2px 12px;
    border-radius: 10px;
}
.black-small-link
{
    color: #ffffff !important;
    background: #333;
    padding: 4px 15px;
    border-radius: 6px;
    transition: all .3s;
    border: 1px solid white;
}
.black-small-link:hover
{
    color: #ffffff !important;
    /* background: #0d6327; */
    padding: 4px 15px;
    border-radius: 6px;
    transition: all .3s;
    border: 1px solid black;
}
.course-main-thumb-col
{
    border: 1px solid #14499021;
    padding: 0 !important;
    height: 100%;
    min-height: 60px !important;
    border-radius: 5px !important;
    background: #f7f7f7;
    transition: all .3s;
}
.course-main-thumb-col:hover
{
    background: #e3f7e9;
    border: 1px solid #d9f7e2;
    transition: all .3s;
}
.course-letter-col
{
    display: block;
    width: 20%;
    height: 100%;
    background: #144990;
    text-align: center;
    float: left;
    clear: none;
    border-radius: 5px 0 0 5px !important;
}
.course-letter-col h3
{
    color: #fefefe;
    font-size: 30px;
    font-weight: 600;
    line-height: 60px;
    padding-top: 5px;
}
.course-name-col
{
    display: block;
    padding-left: 10px;
    width: 80%;
    float: left;
    height: 100%;
    padding-top: 7px !important;
    border-radius: 0 5px 5px 0 !important;
}
.course-name-col h5
{
    font-size: 15px !important;
    font-weight: 600;
    line-height: 20px !important;
}
.course-name-col p
{
    font-size: 13px !important;
    font-weight: 400;
    line-height: 20px !important;
    margin-top: 3px !important;
}
.course-list-ul
{
    list-style: none !important;
    padding: 0 !important;
}
.course-list-ul li
{
    display: inline-flex;
    list-style: none !important;
    margin-right: 12px !important;
    border: 1px solid #c5d0de;
    margin-bottom: 10px !important;
    padding: 0 10px 0 0px;
    background: #ffffff;
    transition: all .3s;
}
.course-list-ul li:hover
{
    background: #ebf3fd;
    transition: all .3s;

}
.course-list-ul li a
{
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 32px;
}
.course-list-ul li:before
{
    transition: all 0.4s;
    content: "\f101";
    font-family: FontAwesome;
    color: #ffffff;
    margin-right: 5px;
    font-size: 16px;
    width: 30px;
    height: 32px;
    background: #144990;
    text-align: center;
    line-height: 31px;
}
.contact-form .form-control {
    padding: 10px 15px;
    border-radius: 3px !important;
    font-size: 14px !important;
}
.contact-dtls-col
{
    padding: 40px;
    background: #fafafa;
    line-height: 28px !important;
}
.contact-main-col
{
    border: 1px solid #f1f1f1;
}
.contact-form-col
{
    padding: 40px;
}
.testimonial-col {
    border: 1px solid #ececec;
    border-radius: 5px !important;
    padding: 15px !important;
    background: #fff;
    box-shadow: 0px 3px 11px -2px #0000001f;
    transition: all .3s;
}
.testimonial-col:hover {
    border: 1px solid #ececec;
    border-radius: 5px !important;
    padding: 15px !important;
    background: rgba(246, 246, 246, 0.51);
    box-shadow: 0px 3px 11px -2px #0000001f;
}
.user-thumb {
    height: 140px !important;
    background: #f1f1f1;
    border: 4px solid #ffffff;
    border-radius: 140px !important;
    overflow: hidden;
    width: 140px !important;
    margin: auto auto !important;
    display: inline-flex !important;
    vertical-align: middle !important;
    box-shadow: 0px 2px 11px -2px #00000040;
}
.user-thumb img
{
    width: 100% !important;
    height: auto;
    margin: auto auto !important;
}
.team-col-main
{
    border: 1px solid #eaeaea;
    padding: 20px !important;
}
.team-content {
    width: 100%;
    padding: 12px 0 0 0;
}
.team-content h5
{
    font-size: 16px !important;
    font-weight: 600;
    line-height: 22px !important;
    text-align: center;
}
.team-content h6
{
    font-size: 13px !important;
    font-weight: 600 !important;
    opacity: .6;
    text-align: center;
}
.team-content p {
    text-align: center;
    font-size: 13px !important;
    line-height: 22px !important;
    font-weight: 500;
}



/* gallery */
.gallery-heads {
    border: 1px solid #eaeaea;
    min-height: 55px;
    padding: 3px 10px 2px 10px;
    font-size: 14px !important;
    font-weight: 500 !important;
    text-align: center;
    line-height: 20px !important;
    background: white;
    margin-bottom: 0 !important;
    text-transform: capitalize;
}
.demo-gallery > ul {
    margin-bottom: 0;
    padding-left: 15px;
}

.demo-gallery > ul > li {
    margin-bottom: 15px;
    /*width: 180px;
    display: inline-block;
    margin-right: 15px;*/
    padding: 0 5px !important;
    list-style: outside none none;
}

.demo-gallery > ul > li a {
    border: 1px solid #c5c5c5;
    border-radius: 0;
    display: block;
    overflow: hidden;
    position: relative;
    float: left;
}

.demo-gallery > ul > li a > img {
    -webkit-transition: -webkit-transform 0.15s ease 0s;
    -moz-transition: -moz-transform 0.15s ease 0s;
    -o-transition: -o-transform 0.15s ease 0s;
    transition: transform 0.15s ease 0s;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    height: 100%;
    width: 100%;
}

.demo-gallery > ul > li a:hover > img {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
}

.demo-gallery > ul > li a:hover .demo-gallery-poster > img {
    opacity: 1;
}

.demo-gallery > ul > li a .demo-gallery-poster {
    background-color: rgba(0, 0, 0, 0.1);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: background-color 0.15s ease 0s;
    -o-transition: background-color 0.15s ease 0s;
    transition: background-color 0.15s ease 0s;
}

.demo-gallery > ul > li a .demo-gallery-poster > img {
    left: 48%;
    margin-left: -10px;
    margin-top: -10px;
    opacity: 0;
    position: absolute;
    top: 48%;
    -webkit-transition: opacity 0.3s ease 0s;
    -o-transition: opacity 0.3s ease 0s;
    transition: opacity 0.3s ease 0s;
}

.demo-gallery > ul > li a:hover .demo-gallery-poster {
    background-color: rgba(0, 0, 0, 0.5);
}

.demo-gallery .justified-gallery > a > img {
    -webkit-transition: -webkit-transform 0.15s ease 0s;
    -moz-transition: -moz-transform 0.15s ease 0s;
    -o-transition: -o-transform 0.15s ease 0s;
    transition: transform 0.15s ease 0s;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    height: 100%;
    width: 100%;
}

.demo-gallery .justified-gallery > a:hover > img {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
}

.demo-gallery .justified-gallery > a:hover .demo-gallery-poster > img {
    opacity: 1;
}

.demo-gallery .justified-gallery > a .demo-gallery-poster {
    background-color: rgba(0, 0, 0, 0.1);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: background-color 0.15s ease 0s;
    -o-transition: background-color 0.15s ease 0s;
    transition: background-color 0.15s ease 0s;
}

.demo-gallery .justified-gallery > a .demo-gallery-poster > img {
    left: 50%;
    margin-left: -10px;
    margin-top: -10px;
    opacity: 0;
    position: absolute;
    top: 50%;
    -webkit-transition: opacity 0.3s ease 0s;
    -o-transition: opacity 0.3s ease 0s;
    transition: opacity 0.3s ease 0s;
}

.demo-gallery .justified-gallery > a:hover .demo-gallery-poster {
    background-color: rgba(0, 0, 0, 0.5);
}

.demo-gallery .video .demo-gallery-poster img {
    height: 48px;
    margin-left: -24px;
    margin-top: -24px;
    opacity: 0.8;
    width: 48px;
}

.demo-gallery.dark > ul > li a {
    border: 3px solid #04070a;
}
/* gallery */


.event-col {
    border: 1px solid #b4dcc0;
}
.event-date {
    text-align: center;
    background: #21a349;
    padding: 9px 10px 6px 10px !important;
}
.event-date .month {
    font-size: 12px !important;
    text-transform: uppercase;
    color: #fff;

    letter-spacing: 3px;
}
.event-date .day {
    font-size: 30px !important;
    font-weight: 600;
    color: #fff;
}
.event-date .year {
    font-size: 12px !important;
    color: #fff;
    letter-spacing: 3px;
}
.event-content {
    padding: 7px 15px !important;
}
.event-content h5 {
    font-size: 16px !important;
    font-weight: 600 !important;
}
.event-content p {
    font-size: 14px !important;
    font-weight: 400 !important;
}
.event-news-panel
{
    padding: 15px 15px !important;
    border: 1px solid #efefef;
    background: #efefefa1;
}
.news-col {
    border: 1px solid #ececec;
    padding: 0 !important;
    transition: all 0.3s ease-out 0s;
}
.news-col:hover
{
    box-shadow: 0 20px 20px rgb(0 0 0 / 14%);
    -webkit-box-shadow: 0 20px 20px rgb(0 0 0 / 14%);
    transform: translateY(-10px);
}
.news-img {
    padding: 0 !important;
    position: relative;
}
.news-date {
    position: absolute;
    bottom: 15px !important;
    left: 15px;
    background: #0b3d80eb;
    width: 55px !important;
    text-align: center;
    padding: 2px 5px;
}
.news-content {
    padding: 15px 25px !important;
    background: #fff;
}
.news-date .month {
    font-size: 11px !important;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 22px;
}
.news-date .day {
    font-size: 24px !important;
    font-weight: 600 !important;
    color: #fff;
    line-height: 18px !important;
}
.news-date .year {
    font-size: 12px !important;
    color: #fff;
    letter-spacing: 1px !important;
    line-height: 22px;
}
.news-content h5 a {
    line-height: 24px !important;
    font-size: 16px !important;
}
.read-more-link {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #144990;
    transition: all .3s;
}
.read-more-link:hover {
    color: #21a349;
    transition: all .3s;
}

.pages-sidebar-item {
    float: left;
    margin-bottom: 60px;
    width: 100%;
}
.pages-sidebar-item-title {
    font-size: 20px;
    margin-bottom: 25px;
}
.imgbox-small {
    width: 95px;
    height: 55px;
    float: left;
    margin: 0;
    display: block;
}
.text-box-right {
    display: block;
    padding: 0px 0px 0px 110px;
    margin: 0px;
    text-align: left;
    float: inherit;
}
.blog-post-info {
    width: 100%;
}

.text-box-right h6 {
    font-size: 14px !important;
    font-weight: 500 !important;
    margin-bottom: 0 !important;
}
.blog-post-info span {
    font-size: 13px;
    color: #828181;
}
.blog-single-col .text-box p
{
    font-size: 15px !important;
    line-height: 24px !important;
}
.latest-post-thumb {
    padding: 15px 0;
    border-bottom: 1px solid #eaeaea;
}
.text-green
{
    color: #21a349 !important;
}





@media screen and (min-width: 320px) and (max-width: 480px)
{
    .top-banner {
        padding: 50px 0 135px 0 !important;
        background: gainsboro;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .signup-drop
    {
        background-color: #212121;
        min-width: 95px !important;
        max-width: 100px !important;
        box-shadow: 0 4px 6px 1px #3e3b3b47;
        border-radius: 5px !important;
    }
    .head-btn
    {
        position: inherit !important;
        left: initial !important;
    }
    .banner-institute-logo {
        position: absolute;
        width: 120px;
        height: 120px;
        background: white;
        border: 6px solid white;
        padding: 5px 5px !important;
        bottom: -90px;
        box-shadow: 0 4px 17px 0 #00000026;
        border-radius: 3px !important;
    }
    .course-banner {
        padding: 60px 0 !important;
        background: gainsboro;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .text-title-medium {
        font-size: 28px;
        line-height: 42px;
    }
    .course-fee-col {
        background: #484848;
        border-radius: 7px !important;
        position: relative;
        margin-top: 15px !important;
        padding: 20px !important;
        box-shadow: 0 3px 12px -2px #0000004f;
        /* border: 1px solid #000853; */
    }
    .light-bg-title {
        background: whitesmoke;
        padding: 5px 12px !important;
        font-size: 16px !important;
    }
    .why-tab-item
    {
        width: 100% !important;
        border: 1px solid #144990;
        border-radius: 5px;
    }
    .why-tab-content {
        padding: 10px 0 !important;
    }
}

@media screen and (min-width: 481px) and (max-width: 1024px)
{
    .top-banner {
        padding: 50px 0 135px 0 !important;
        background: gainsboro;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .banner-institute-logo {
        position: absolute;
        width: 120px;
        height: 120px;
        background: white;
        border: 6px solid white;
        padding: 5px 5px !important;
        bottom: -90px;
        box-shadow: 0 4px 17px 0 #00000026;
        border-radius: 3px !important;
    }
    .course-fee-col {
        background: #484848;
        border-radius: 7px !important;
        position: relative;
        margin-top: 15px !important;
        padding: 20px !important;
        box-shadow: 0 3px 12px -2px #0000004f;
        /* border: 1px solid #000853; */
    }

}
/* 19 - 05 - 2021  end */

/* Css added by dhanasekar */
#objfit-iaggg
{
    object-fit: contain!important;
}

/* button open css */
.btn-gn:hover:after {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.btn-gn::after{
    content: "";
    background-color: #0572aa!important;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 25px;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.btn-gn
{
    position: relative;
    display: inline-block;
    line-height: 26px;
    color: #fff !important;
    border-radius: 25px;
    text-align: center;
    padding: 10px 40px;
    border: none;
    z-index: 1;
    background-color: #20A8D8;
}
.certi-hght
{
    min-height: 50vh;
  
}
.padd-2
{
    padding-left: 3%;
}


/* Css added by dhanasekar */