.jms-choose-from-playlist-thumb-card{
    border-radius: 5px;
    border-radius: 5px;
    background-size: cover;
    height: 125px;
    padding-top: 10px;
    margin-top:10px;
    margin-left: 7px
}

.jms-choose-from-playlist-thumb-title{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
    color: white;
    padding: 1px 7px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 11px;
    background: #2f2d2d;
    position: absolute;
    background-repeat: no-repeat;
    top: 15px;
    left: 30px;
}
.jms-choose-from-playlist-thumb-input{
    position: absolute;
    top: 40px;
    //right: 30px;
    left:24px;
}
.jms-choose-from-playlist-thumb-actions{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
    color: black;
    padding: 7px 7px;
     border-radius: 0px 0px 4px 4px;
    font-weight: 600;
    font-size: 11px;
    background: white;
    position: absolute;
    background-repeat: no-repeat;
    bottom: 0px;
    right: 30px;
    left:22px;
    width: -webkit-fill-available;
    margin-right: 37px;
    height: 45px;
}

.jms-choose-from-playlist-thumb-size{
    color: white;
    padding: 1px 7px;
    border-radius: 4px;
    font-size: 11px;
    background: #2f2d2d;
    position: absolute;
    top: 15px;
    right: 30px;
}


.jms-choose-from-playlist-thumb-format{
    color: white;
    padding: 1px 7px;
    border-radius: 4px;
    font-size: 11px;
    background: #2f2d2d;
    position: absolute;
    bottom: 5px;
    right: 25px;
}
.jms-choose-from-playlist-thumb-format-choose{
    color: white;
    padding: 1px 7px;
    border-radius: 4px;
    font-size: 11px;
   
    position: absolute;
    top: 15px;
    right: 22px;
}
.jms-thumb-card-processing{
    position: absolute;
    top: 38%;
    left: 34%;
    color: #ffffff;
    background: #807c7c;
    border-radius: 6px;
    font-size: 13px;
    padding: 0px 10px 0px 10px;
}
// .progress{
//     position: absolute;
//     bottom: 50px;
//     left: 30px;
//     width: 80%;
// }