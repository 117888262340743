.cardscroll{
    height: 420px;
    overflow-y: scroll;
	overflow-x: hidden;
	/* background-color: red; */
}
.headcourse{
	margin-left: 8px;
	font-size: 15px;
	color: #333;
	/* font-weight: 600; */
}
.req{
	font-size: 15px;
	font-weight: bold;
	color: orange;
	margin-right: 5px;
}
.rej{
	font-size: 15px;
	font-weight: bold;
	color: red;
	margin-right: 5px;

}
.app{
	font-size: 15px;
	font-weight: bold;
	color: green;
	margin-right: 5px;

}
.unreq{
	font-size: 15px;
	font-weight: bold;
	color: #87CEEB;
	margin-right: 5px;

}

.cardscroll::-webkit-scrollbar-track
{
	background-color: rgba(207, 207, 207, 0.418);
	/* display: none; */
}

.cardscroll::-webkit-scrollbar
{
	width: 3px;
	background-color: #F5F5F5;
	/* display: none; */

}

.cardscroll::-webkit-scrollbar-thumb
{
    border-radius: 10px;
	background-color: #a5a5a5;
	/* display: none; */

}